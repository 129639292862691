/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const FreeskiKilpis = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vapaalasku/naisten-finlav-lumiturvallisuus-1-yllas',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Naisten FINLAV Lumiturvallisuus 1 YLLÄS',
    description:
      'Tervetuloa mukaan Ylläkselle oppimaan lumiturvallisuudesta. Lumiturvallisuus 1 -kurssi antaa perustiedot lumivyöryistä ja lumivyöryvaarasta. Kurssilla opitaan tunnistamaan lumivyörymaasto, saadaan työkaluja päätöksentekoon ja harjoitellaan miten toimia pelastustilanteessa.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="naisten-finlav-lumiturvallisuus-1-yllas"
    >
      <div className="camp-text-content">
        <h2>Naisten FINLAV Lumiturvallisuus 1 YLLÄS</h2>
        <h3>18.-19.2.2023</h3>
        <p>
          <span className="bolded">
            Tervetuloa mukaan Ylläkselle oppimaan lumiturvallisuudesta.
            Lumiturvallisuus 1 -kurssi antaa perustiedot lumivyöryistä ja
            lumivyöryvaarasta. Kurssilla opitaan tunnistamaan lumivyörymaasto,
            saadaan työkaluja päätöksentekoon ja harjoitellaan miten toimia
            pelastustilanteessa.
          </span>
          <br />
          <br />
          FINLAV Lumiturvallisuus 1 on Suomen Lumivyörykoulutus –järjestelmän
          mukainen koulutus ja suunnattu kaikille, jotka liikkuvat talvisaikaan
          tuntureilla ja vuorilla. Koulutus on suunnattu harrastustoiminnassaan
          oppia tarvitseville. Kurssi antaa perustiedot lumivyöryistä ja
          lumivyöryvaarasta.
          <br />
          <br />
          Ylläksen alue sopii erinomaisesti lumiturvallisuustaitojen hiomiseen.
          Alueen tunturit ja ilmasto mahdollistavat mielenkiintoiset olosuhteet
          ennakoivien lumiturvallisuustaitojen opetteluun. Lisäksi kurssi antaa
          mahdollisuuden tutustua Ylläksen luonnonvaraisten tuntureiden
          loistaviin vapaalasku mahdollisuuksiin.
        </p>
        <br />
        <p>
          <span className="bolded">KURSSIN SISÄLTÖ</span> <br />
          - Lumivyöryjen perusteet <br />
          - Turvallisuusajattelu, inhimilliset tekijät <br />
          - Päätöksenteko vyörykorttia apuna käyttäen <br />
          - Sää- ja vyöryennusteiden tulkinta <br />
          - Reittisuunnittelun perusteet kartalla ja maastossa <br />
          - Kartta, GPS tai kännykkä paikantamisen apuna <br />
          - Vyörypelastusvälineiden käyttö ja kaveripelastusharjoitus
          <br />
          <br />
          Sinun tulee osata laskea hallitusti luonnonvaraisilla, ei hoidetuilla
          rinnealueilla sekä ymmärtää ja hyväksyä luonnonvaraisella rinteellä
          laskemiseen sisältyvät riskit. Kurssille osallistuvalle suositellaan
          hankkimaan off-piste hiihdon kattava tapaturmavakuutus.
        </p>
        <br />
        <p>
          <span className="bolded">TARVITTAVAT VÄLINEET</span> <br />
          - Off-pistesukset vaellussiteellä / Splitboard / Lumilauta sekä
          lumikengät <br />
          - Nousukarvat <br />
          - Monot / lumilautakengät, <br />
          - Sauvat <br />
          - Kypärä <br />
          - Piippari, lapio, sondi <br />- Reppu 30-40L, johon saat kiinnitettyä
          suksesi/lautasi /lumikengät <br />
          - Laskettelulasit <br />
          - Juomapullo tai termospullo <br />
          - Säätilaan sopiva vaatetus (tuulta pitävä ulkovaatetus, pipo ja
          kauluri, välikerros esim. fleece, aluskerrasto, laskusukat), <br />
          - Reppuun mukaan lämmin taukotakki ja varahanskat.
          <br />
          <br />
          Jos sinulta puuttuu jotakin yllä mainituista, voit kysyä näitä meiltä
          vuokralle.
        </p>
        <br />
        <p>
          <span className="bolded">Kouluttaja</span> <br />
          Antte Lauhamaa on lappilainen vapaalaskija, lumivyöryasiantuntija ja
          opas. Anten kokemus vuorilla liikkumisesta alkaa 90-luvun
          alkupuolelta, jolloin hän aloitti vapaalaskun telemark-suksilla.
          Antelle hiihtäminen ei ole pelkkä harrastus vaan kutsumus ja intohimo,
          jonka avulla voi toteuttaa itseään.
          <br />
          <br />
          Koulutuksena Antella on kansainvälisesti hyväksytty
          lumiturvallisuusalan tutkinto CAA 2, WAFA (Wilderness Advanced First
          Aid) sekä SHKY:n Ski Patrol taso 3.
          <br />
          <br />
          <span className="bolded">Kurssille mahtuu</span> maksimissaan 8
          naista, minimäärä kurssin toteutumiseen on viisi.
          <br />
          <br />
          <span className="bolded">Hinta:</span> 340 € (sis. alv) <br />
          Hinta ei sisällä matkoja, majoitusta, aterioita tai välineitä.
        </p>
        <br />
        <p>
          Majoitus Ylläkseltä löytyy kattavasti majoitusta kurssin aikana.
          Kurssin aloitus ja päätöspaikka on Äkäslompolon puolella, joten
          ensisijaisesti majoitusta kannattaa katsoa tältä puolelta tunturia.
          Omalla autolla liikkuvalle ei tosin ole ongelma vaikka majoitus on
          Ylläsjärven puolella. Alkuun majoituksien etsinnässä pääset
          osoitteesta{' '}
          <a
            className="bolded"
            href="https://www.yllas.fi/majoitus.html"
            target="_blank"
            rel="noreferrer"
          >
            www.yllas.fi/majoitus.html
          </a>
        </p>
        <br />
        <p>
          <span className="bolded">
            Ilmoittaudu mukaan sähköpostitse{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>{' '}
            ja toimi näin:
          </span>{' '}
          <br />
          OTSIKKO:{' '}
          <span className="bolded">
            Naisten FINLAV lumiturvallisuus 1 YLLÄS
          </span>{' '}
          <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi kokemuksesi vapaalaskusta? <br />
          Millä välineillä lasket? <br />
          Onko sinulla omat lumiturvallisuusvälineet? <br />
          Mikä on piipparisi merkki ja malli? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
        </p>
        <br />
        <p>
          <span className="bolded">Retken varaus- ja peruutusehdot:</span>{' '}
          <br />
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          laskun. Kun lasku on maksettu, on paikka kurssille varattu.
          <br />
          <br />
          Asiakkaan peruutukset 2 kuukautta ennen retken alkua: 100% palautus{' '}
          <br />
          Asiakkaan peruutukset 1 kuukausi ennen retken alkua: 50% palautus{' '}
          <br />
          Asiakkaan peruutukset alle kuukausi ennen retken alkua: Ei hyvitystä{' '}
          <br />
          <br />
          Lue lisää varaus – ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
          <br />
          <br />
          HUOM! Pidäthän huolen, että sinulla on voimassa oleva
          vapaalaskuvakuutus kurssin aikana.
          <br />
          <br />
          Tekisikö mieli mukaan tälle ainutlaatuiselle vaellukselle? Mikäli
          sinulla on kysyttävää vaelluksesta, voit olla yhteydessä Anetteen joko
          meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/freeski/otto+maria-yllas-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(relativePath: { eq: "images/freeski/Antte.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/freeski/otto+maria-yllas-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default FreeskiKilpis;
